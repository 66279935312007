/**
 * @generated SignedSource<<e0ff6fe41d074b7eadf07d9678ff1c20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BuySharedCategoryTile_categoryTile$data = {
  readonly convertedAmountList: ReadonlyArray<{
    readonly amount: number | null;
    readonly currency: Currency | null;
    readonly linkData: {
      readonly isLinkable: boolean | null;
      readonly path: string | null;
      readonly text: string | null;
    };
  } | null> | null;
  readonly image: string | null;
  readonly linkData: {
    readonly isLinkable: boolean | null;
    readonly path: string | null;
    readonly text: string | null;
  };
  readonly rank: number | null;
  readonly " $fragmentType": "BuySharedCategoryTile_categoryTile";
};
export type BuySharedCategoryTile_categoryTile$key = {
  readonly " $data"?: BuySharedCategoryTile_categoryTile$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedCategoryTile_categoryTile">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedCategoryTile_categoryTile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "convertedAmountList",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseCategoryTiles",
  "abstractKey": null
};
})();

(node as any).hash = "417ab42130d0bdf04b729bd821323998";

export default node;
