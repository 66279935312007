import { useState } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { BuySharedCMSFAQItem } from './BuySharedCMSFAQItem';
import { frequentlyAskedQuestions as frequentlyAskedQuestionsTitle } from '../buyMessages';

import styles from './BuySharedCMSFAQ.scss';

const BuySharedCMSFAQComponent = ({ buyPage }) => {
    const { frequentlyAskedQuestions, meta } = buyPage;
    const [expandedItem, setExpandedItem] = useState(-1);
    const { header } = meta || {};

    if (!frequentlyAskedQuestions?.length) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>{frequentlyAskedQuestionsTitle(header)}</div>
            <div>
                {frequentlyAskedQuestions.map(({ question, answer }, index) => {
                    return (
                        <BuySharedCMSFAQItem
                            key={question}
                            index={index}
                            question={question}
                            answer={answer}
                            isExpanded={index === expandedItem}
                            onClick={setExpandedItem}
                        />
                    );
                })}
            </div>
        </div>
    );
};

BuySharedCMSFAQComponent.propTypes = {
    buyPage: PropTypes.object.isRequired,
};

export const BuySharedCMSFAQ = createFragmentContainer(BuySharedCMSFAQComponent, {
    buyPage: graphql`
        fragment BuySharedCMSFAQ_buyPage on ItemSearchQueryConnection {
            meta {
                header
            }
            frequentlyAskedQuestions {
                question
                answer
            }
        }
    `,
});
