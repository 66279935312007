/**
 * @generated SignedSource<<e9996f6f1f14378448f878e4e2eef249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespCategoryTiles_itemSearch$data = {
  readonly categoryTiles: ReadonlyArray<{
    readonly rank: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"BuySharedCategoryTile_categoryTile">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedCategoryTilesTracking_itemSearch">;
  readonly " $fragmentType": "BuyRespCategoryTiles_itemSearch";
};
export type BuyRespCategoryTiles_itemSearch$key = {
  readonly " $data"?: BuyRespCategoryTiles_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespCategoryTiles_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespCategoryTiles_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseCategoryTiles",
      "kind": "LinkedField",
      "name": "categoryTiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedCategoryTile_categoryTile"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuySharedCategoryTilesTracking_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "ccc1a06e1d7165f5a8cf150a65b33bfc";

export default node;
