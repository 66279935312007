/**
 * @generated SignedSource<<115047eab71e3870d0910ed4a2380b6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedResultContainer_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_viewer" | "useSbSharedItemTracking_viewer">;
  readonly " $fragmentType": "BuySharedResultContainer_viewer";
};
export type BuySharedResultContainer_viewer$key = {
  readonly " $data"?: BuySharedResultContainer_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedResultContainer_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedResultContainer_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "6105e0f08959373de013a9b57932f8c1";

export default node;
