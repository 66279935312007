/**
 * @generated SignedSource<<b08144865ba59061829067727db44fd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespTopNav_buyPage$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineTopAppliedFilters_itemSearch" | "SbRespSortItems_itemSearch" | "SharedFollowTopButton_itemSearch" | "useMeasurementUnit_itemSearch">;
  readonly " $fragmentType": "BuyRespTopNav_buyPage";
};
export type BuyRespTopNav_buyPage$key = {
  readonly " $data"?: BuyRespTopNav_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespTopNav_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespTopNav_buyPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineTopAppliedFilters_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSortItems_itemSearch"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_itemSearch",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParam",
          "kind": "LinkedField",
          "name": "appliedFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchBrowseParsedParamValue",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlLabel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "15423df2d5d0cfff1335d4ffef204b62";

export default node;
