import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import EditorialModule from 'dibs-editorial-module/exports/EditorialModule';
import classnames from 'classnames';

import styles from './SharedWordpressArticles.scss';

const SharedWordpressArticlesComponent = ({
    itemSearch,
    classname,
    headerClassName,
    useWhiteBg,
}) => {
    const editorialArticles = itemSearch?.editorialArticles;
    if (!editorialArticles?.totalResults) {
        return null;
    }
    return (
        <div className={classnames(styles.container, classname)}>
            <EditorialModule
                editorialArticles={editorialArticles}
                useWhiteBg={useWhiteBg}
                headerClassName={headerClassName}
            />
        </div>
    );
};

SharedWordpressArticlesComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    classname: PropTypes.string,
    useWhiteBg: PropTypes.bool,
    headerClassName: PropTypes.string,
};

export const SharedWordpressArticles = createFragmentContainer(SharedWordpressArticlesComponent, {
    itemSearch: graphql`
        fragment SharedWordpressArticles_itemSearch on ItemSearchQueryConnection {
            editorialArticles(first: 8) {
                totalResults
                ...EditorialModule_editorialArticles
            }
        }
    `,
});
