import PropTypes from 'prop-types';
import ArrowDown from 'dibs-icons/exports/legacy/ArrowDown';
import ArrowUp from 'dibs-icons/exports/legacy/ArrowUp';
import { Link } from 'dibs-elements/exports/Link';

import styles from './BuySharedCMSFAQItem.scss';

export const BuySharedCMSFAQItem = ({ index, question, answer, onClick, isExpanded }) => {
    const expansionHandler = () => {
        onClick(isExpanded ? -1 : index);
    };

    return (
        <div className={styles.wrapper}>
            {isExpanded ? (
                <>
                    <div className={`${styles.questionWrapper} ${styles.questionWrapperExpanded}`}>
                        <span className={styles.question}>{question}</span>
                        <Link onClick={expansionHandler}>
                            <ArrowUp className={styles.icon} />
                        </Link>
                    </div>
                    <div
                        className={`${styles.answer} ${styles.answerExpanded}`}
                        dangerouslySetInnerHTML={{ __html: answer }}
                    />
                </>
            ) : (
                <>
                    <Link
                        underline="none"
                        className={styles.questionWrapper}
                        onClick={expansionHandler}
                    >
                        <span className={styles.question}>{question}</span>
                        <ArrowDown className={styles.icon} />
                    </Link>
                    <div className={styles.answer} dangerouslySetInnerHTML={{ __html: answer }} />
                </>
            )}
        </div>
    );
};

BuySharedCMSFAQItem.propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
};
