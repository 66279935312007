import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import styles from './BuySharedTitle.scss';

function BuySharedTitleComponent({ buyPage }) {
    const header = buyPage?.meta?.header;
    return (
        <h1 className={styles.header} data-tn="buy-page-header">
            {header}
        </h1>
    );
}

BuySharedTitleComponent.propTypes = {
    buyPage: PropTypes.shape({
        meta: PropTypes.shape({
            header: PropTypes.string.isRequired,
        }),
    }),
};

export const BuySharedTitle = createFragmentContainer(BuySharedTitleComponent, {
    buyPage: graphql`
        fragment BuySharedTitle_buyPage on ItemSearchQueryConnection {
            meta {
                header
            }
        }
    `,
});
