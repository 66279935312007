/**
 * @generated SignedSource<<9cf08fc8d7afabe99e3dc42ef6476989>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespRefineMenu_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_viewer">;
  readonly " $fragmentType": "BuyRespRefineMenu_viewer";
};
export type BuyRespRefineMenu_viewer$key = {
  readonly " $data"?: BuyRespRefineMenu_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespRefineMenu_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespRefineMenu_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8977a5836480d7532ae50368c5bd10b0";

export default node;
