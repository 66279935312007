/**
 * @generated SignedSource<<7c3384dfc51268361ad3067b10575c1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespRefineMenu_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_user" | "SbSharedRefineMenuFilters_user">;
  readonly " $fragmentType": "BuyRespRefineMenu_user";
};
export type BuyRespRefineMenu_user$key = {
  readonly " $data"?: BuyRespRefineMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespRefineMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespRefineMenu_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "594f3ded15f3fccda3d362368c268c50";

export default node;
