/**
 * @generated SignedSource<<f2f256cf4dea6e5fda53691865502bfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BuyRespRefineMenu_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly canBeDismissed: boolean | null;
    readonly name: string | null;
  } | null> | null;
  readonly topCategoryL1: VerticalType | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_itemSearch" | "SbSharedRefineMenuFilters_itemSearch" | "SbSharedRefineMenuSearchWithin_itemSearch">;
  readonly " $fragmentType": "BuyRespRefineMenu_itemSearch";
};
export type BuyRespRefineMenu_itemSearch$key = {
  readonly " $data"?: BuyRespRefineMenu_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespRefineMenu_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespRefineMenu_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topCategoryL1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeDismissed",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuSearchWithin_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "d272dfde4ec18583a0571a64a28e8218";

export default node;
