import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import styles from './BuySharedDescription.scss';

function BuySharedDescriptionComponent({ buyPage }) {
    return buyPage.description && <div className={styles.description}>{buyPage.description}</div>;
}

BuySharedDescriptionComponent.propTypes = {
    buyPage: PropTypes.object.isRequired,
};

export const BuySharedDescription = createFragmentContainer(BuySharedDescriptionComponent, {
    buyPage: graphql`
        fragment BuySharedDescription_buyPage on ItemSearchQueryConnection {
            description
        }
    `,
});
