import { initializeDBL } from 'dibs-buyer-layout/src/entries/index';
import { buyRespRootQuery } from '../../queries/buyRespRoot';
import { BuyRespLayout } from '../../buy/BuyRespLayout';
import { createEntry } from '../createEntry';
import { initSharedDatadogRum, sbTypes } from 'dibs-buyer-layout/exports/initSharedDatadogRum';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';

import { defaultReducers, actionSanitizer } from '../../reducers/reducerUtils';
import { sbSharedReducers } from '../../reducers/sbSharedReducers';
const options = {
    reducers: {
        ...defaultReducers,
        ...sbSharedReducers,
    },
    actionSanitizer,
};

initSharedDatadogRum({ page: PAGE_TYPE.BROWSE, device: 'resp', pageType: sbTypes.buy });
initializeDBL(options).then(({ store }) => {
    createEntry({
        Container: BuyRespLayout,
        store,
        query: buyRespRootQuery,
    });
});
