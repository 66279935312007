import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { SbRespSortItems } from '../../finding/SbRespSortItems/SbRespSortItems';
import { SbRespRefineTopAppliedFilters } from '../../finding/SbRespRefineTopNav/SbRespRefineTopAppliedFilters';
import { sortOrderLabel } from '../../shared/sharedMessages';
import { SharedFollowTopButton } from '../../shared/SharedFollowTopButton/SharedFollowTopButton';

// styles
import styles from './buyRespTopNav.scss';

function BuyRespTopNavComponent({ buyPage: itemSearch, user = {} }) {
    return (
        <div className={styles.wrapper}>
            <CurrencyProvider
                render={({ currency }) => (
                    <>
                        <div className={styles.topSection}>
                            <SharedFollowTopButton itemSearch={itemSearch} user={user} />
                            <div className={styles.sortAndPriceContainer}>
                                <span className={styles.label}>{sortOrderLabel}</span>
                                <SbRespSortItems itemSearch={itemSearch} />
                            </div>
                        </div>
                        <div className={styles.middleSection}>
                            <div className={styles.appliedFiltersWrapper}>
                                <SbRespRefineTopAppliedFilters
                                    currency={currency}
                                    itemSearch={itemSearch}
                                    user={user}
                                />
                            </div>
                        </div>
                    </>
                )}
            />
        </div>
    );
}

BuyRespTopNavComponent.propTypes = {
    buyPage: PropTypes.object,
    user: PropTypes.object,
};

const mapStateToProps = ({ relayVariables }) => {
    return {
        isClient: relayVariables.variables.isClient,
    };
};

export const BuyRespTopNav = createFragmentContainer(
    connect(mapStateToProps)(BuyRespTopNavComponent),
    {
        buyPage: graphql`
            fragment BuyRespTopNav_buyPage on ItemSearchQueryConnection {
                ...SbRespRefineTopAppliedFilters_itemSearch
                ...SbRespSortItems_itemSearch
                ...useMeasurementUnit_itemSearch
                ...SharedFollowTopButton_itemSearch
            }
        `,

        user: graphql`
            fragment BuyRespTopNav_user on User {
                ...useMeasurementUnit_user
                ...SbRespRefineTopAppliedFilters_user
                ...SharedFollowTopButton_user
            }
        `,
    }
);
