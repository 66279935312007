import type { BuySharedCategoryTile_categoryTile$data as CategoryTile } from '../BuyCategoryTiles/__generated__/BuySharedCategoryTile_categoryTile.graphql';
import { defineMessages, useIntl } from 'dibs-react-intl';

const messages = defineMessages({
    categoryL2priceRange: {
        id: 'buyCategoryTiles.categoryL2priceRange',
        defaultMessage: '{categoryL2DisplayName} {priceRange}',
    },
});

export const useBuyCategoryTileData = ({
    currency,
    categoryTile,
}: {
    currency: string;
    categoryTile: CategoryTile | null | undefined;
}): {
    linkData: { path: string | null; text: string | null; isLinkable: boolean | null };
    image: string;
} | null => {
    const { formatMessage } = useIntl();
    const { linkData: tileLinkData, image, rank, convertedAmountList } = categoryTile || {};

    if (!tileLinkData || !image) {
        return null;
    }

    let linkData;

    // Price range ("[categoryL2] Under xxxx USD/EUR/AUD...") category tile always comes first.
    if (rank === 1) {
        const convertedAmount = (convertedAmountList || []).find(
            amount => amount?.currency === currency
        );

        const convertedAmountLinkData = convertedAmount?.linkData;

        if (!convertedAmountLinkData) {
            return null;
        }

        linkData = {
            ...convertedAmountLinkData,
            ...{
                text: formatMessage(messages.categoryL2priceRange, {
                    categoryL2DisplayName: tileLinkData?.text,
                    priceRange: convertedAmountLinkData?.text,
                }).trim(),
            },
        };
    } else {
        linkData = tileLinkData;
    }

    if (!linkData?.path || !linkData?.text) {
        return null;
    }

    return {
        linkData,
        image,
    };
};
