import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { Breadcrumbs } from 'dibs-elements/exports/Breadcrumbs';

function BuySharedBreadcrumbsComponent({ buyPage }) {
    return buyPage.breadcrumbs?.length ? (
        <Breadcrumbs breadcrumbs={buyPage.breadcrumbs} isLastItemActive={false} />
    ) : null;
}

BuySharedBreadcrumbsComponent.propTypes = {
    buyPage: PropTypes.object.isRequired,
};

export const BuySharedBreadcrumbs = createFragmentContainer(BuySharedBreadcrumbsComponent, {
    buyPage: graphql`
        fragment BuySharedBreadcrumbs_buyPage on ItemSearchQueryConnection {
            breadcrumbs {
                text
                path
                isLinkable
            }
        }
    `,
});
