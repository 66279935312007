/**
 * @generated SignedSource<<58d8c236bc364d1d93ea1c5ed60e6019>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedRelatedPages_buyPage$data = {
  readonly popularSearchesLink: {
    readonly path: string | null;
    readonly text: string | null;
  };
  readonly relatedBuyPages: ReadonlyArray<{
    readonly anchorText: string | null;
    readonly url: string | null;
  } | null> | null;
  readonly " $fragmentType": "BuySharedRelatedPages_buyPage";
};
export type BuySharedRelatedPages_buyPage$key = {
  readonly " $data"?: BuySharedRelatedPages_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedRelatedPages_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedRelatedPages_buyPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InternalBuyLinkType",
      "kind": "LinkedField",
      "name": "relatedBuyPages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "anchorText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "popularSearchesLink",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "db8cb7640878e75548c9c09d754f7e4b";

export default node;
