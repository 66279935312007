/**
 * @generated SignedSource<<47f557b2e0b31366d657478c0abaf1e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedWordpressArticles_itemSearch$data = {
  readonly editorialArticles: {
    readonly totalResults: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"EditorialModule_editorialArticles">;
  } | null;
  readonly " $fragmentType": "SharedWordpressArticles_itemSearch";
};
export type SharedWordpressArticles_itemSearch$key = {
  readonly " $data"?: SharedWordpressArticles_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedWordpressArticles_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedWordpressArticles_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 8
        }
      ],
      "concreteType": "WordpressArticlesType",
      "kind": "LinkedField",
      "name": "editorialArticles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalResults",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditorialModule_editorialArticles"
        }
      ],
      "storageKey": "editorialArticles(first:8)"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "695359ff56d13b25ef3bb35ac03bd0f1";

export default node;
