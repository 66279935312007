import { graphql } from 'react-relay/legacy';
import { QP_PAGE_SIZE_VALUE_DEFAULT } from '../constants/queryParamConstants';

const buyRespRootQuery = graphql`
    query buyRespRootQuery(
        $uriRef: String!
        $localeOrigin: String!
        $isTrade: Boolean!
        $showSeller: Boolean!
        $first: Int!
        $page: Int!
        $rootId: String!
        $fetchRegionalInfo: Boolean = false
        $userZipCode: String!
        $userCountryCode: String
        $fetchUser: Boolean!
        $userId: String!
        $personalizationId: String = ""
        $guestId: String = ""
        $rerankUserId: String = ""
        $rerankGuestId: String = ""
        $isClient: Boolean!
        $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
        $followSearchPages: [String]
        $priceBookName: String
        $regions: String = ""
        $regionsList: [String]
        $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
    ) {
        viewer {
            ...BuyRespLayout_viewer
            buyPage: itemSearch(
                userId: $personalizationId
                guestId: $guestId
                uriRef: $uriRef
                localeOrigin: $localeOrigin
                first: $first
                page: $page
                buyPageId: $rootId
                userCountryCode: $userCountryCode
                regionsList: $regionsList
                priceBookName: $priceBookName
                regions: $regions
            ) {
                pageExists
                redirect {
                    location
                }
            }
        }
    }
`;

const buyRespVariables = {
    $afterDisplay: false,
    uriRef: '',
    localeOrigin: '',
    userId: '',
    isTrade: false,
    showSeller: false,
    isClient: false,
    fetchUser: false,
    first: QP_PAGE_SIZE_VALUE_DEFAULT,
    rootId: '',
    userCountryCode: '',
    isSbTileCarouselTest: true,
    pageDisplayEnum: 'searchAndBrowse',
};

export { buyRespRootQuery, buyRespVariables };
