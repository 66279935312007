/**
 * @generated SignedSource<<0f434eea5066258bd81da87a15b5ed53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedPersonalizedSearchItems_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_viewer">;
  readonly " $fragmentType": "BuySharedPersonalizedSearchItems_viewer";
};
export type BuySharedPersonalizedSearchItems_viewer$key = {
  readonly " $data"?: BuySharedPersonalizedSearchItems_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedPersonalizedSearchItems_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedPersonalizedSearchItems_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "15aefe7111877bc7478c2ade556d2f9b";

export default node;
