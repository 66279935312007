import { ReactNode, FC, useState } from 'react';
import { graphql, createFragmentContainer } from 'react-relay/legacy';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'dibs-react-intl';
import { SearchTag } from 'dibs-elements/exports/SearchTag';
import { Link } from 'dibs-elements/exports/Link';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import {
    getAppliedFilterValues,
    closeAppliedFilterHandler,
    clearAllAppliedFiltersHandler,
    getValidSharedAppliedFilters,
} from '../SbSharedAppliedFilters/sbSharedAppliedFiltersHelpers';
import { getFilterValues } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { isBatchAppliedFilter } from '../SbRespRefineMenu/SbRespRefineMenuFilters/sbRespRefineMenuFiltersMap';
import { SbRespRefineTopAppliedFilters_user$data } from './__generated__/SbRespRefineTopAppliedFilters_user.graphql';
import { SbRespRefineTopAppliedFilters_itemSearch$data } from './__generated__/SbRespRefineTopAppliedFilters_itemSearch.graphql';
import { useMeasurementUnit } from '../useMeasurementUnit';
import { SbRespAppliedFiltersItem } from '../SbRespAppliedFilters/SbRespAppliedFiltersItem';

import styles from './SbRespRefineTopAppliedFilters.scss';

type Props = {
    itemSearch: SbRespRefineTopAppliedFilters_itemSearch$data;
    user: SbRespRefineTopAppliedFilters_user$data;
    currency: string;
};

const DESKTOP_VISIBLE_FILTER_LIMIT = 6;
const TABLET_VISIBLE_FILTER_LIMIT = 4;

type ReduxStateType = {
    filters: {
        generatedUriRef?: string;
    };
};

const SbRespRefineTopAppliedFiltersComponent: FC<Props> = ({ itemSearch, user, currency }) => {
    const { isTablet } = useServerVarsContext();
    const [isViewingMoreFilters, setIsViewingMoreFilters] = useState(false);
    const dispatch = useDispatch();
    const generatedUriRef = useSelector((state: ReduxStateType) => state.filters.generatedUriRef);
    const [measurementUnit] = useMeasurementUnit({ user, itemSearch });
    const onFilterClose = closeAppliedFilterHandler(dispatch);
    const onClearAllFilters = clearAllAppliedFiltersHandler(dispatch, { itemSearch });

    const { appliedFilters, filters = [] } = itemSearch || {};
    if (!appliedFilters || !appliedFilters.length) {
        return null;
    }
    const validAppliedFilters = getValidSharedAppliedFilters(appliedFilters);

    const allFilterTags = validAppliedFilters.reduce(
        (
            acc: ReactNode[],
            appliedFilter: NonNullable<
                SbRespRefineTopAppliedFilters_itemSearch$data['appliedFilters']
            >[0]
        ) => {
            const filterName = appliedFilter?.name;
            if (!appliedFilter || !filterName || !filters || !filters.length) {
                return acc;
            }
            const isBatchApplied = isBatchAppliedFilter(filterName);
            const appliedFilterValues = getAppliedFilterValues({ appliedFilter, isBatchApplied });
            if (appliedFilterValues) {
                appliedFilterValues.forEach((filterValue, index) => {
                    const availableValues = [...getFilterValues(filters, filterName)];
                    acc.push(
                        <SbRespAppliedFiltersItem
                            key={`${filterName}-${index}`}
                            currency={currency}
                            filterName={filterName}
                            localizedFilterName={appliedFilter.localizedFilterName}
                            filterValue={isBatchApplied ? null : filterValue}
                            availableValues={availableValues}
                            onCloseClick={event =>
                                onFilterClose({
                                    filterName,
                                    filterValue,
                                    generatedUriRef,
                                    isBatchApplied,
                                    event,
                                })
                            }
                            measurementUnit={measurementUnit}
                        />
                    );
                });
            }
            return acc;
        },
        []
    );
    const filterLimit = isTablet ? TABLET_VISIBLE_FILTER_LIMIT : DESKTOP_VISIBLE_FILTER_LIMIT;
    const displayedTags = allFilterTags.slice(0, filterLimit);
    const extraTags = allFilterTags.slice(filterLimit, allFilterTags.length);
    const extraTagCount = extraTags.length;
    if (allFilterTags.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            {displayedTags}
            {isViewingMoreFilters && extraTags}
            {!!extraTagCount && (
                <Link onClick={() => setIsViewingMoreFilters(!isViewingMoreFilters)}>
                    <SearchTag
                        isActive={false}
                        hasDeleteButton={false}
                        dataTn={'more-filters-button'}
                    >
                        <div className={styles.moreFiltersButton}>
                            {isViewingMoreFilters ? (
                                <FormattedMessage
                                    id="SbRespTopAppliedFilters.viewLess"
                                    defaultMessage="View Less"
                                />
                            ) : (
                                <FormattedMessage
                                    id="SbRespTopAppliedFilters.additionalFilters"
                                    defaultMessage="+ {extraTagCount} more"
                                    values={{ extraTagCount }}
                                />
                            )}
                        </div>
                    </SearchTag>
                </Link>
            )}
            <Link
                className={styles.clearAllButton}
                dataTn="applied-filters-clear-all"
                onClick={event => {
                    onClearAllFilters({ event });
                }}
                buyerLinkType="standardLink"
            >
                <FormattedMessage
                    id="SbRespTopAppliedFilters.clearAll"
                    defaultMessage="Clear All"
                />
            </Link>
        </div>
    );
};

export const SbRespRefineTopAppliedFilters = createFragmentContainer(
    SbRespRefineTopAppliedFiltersComponent,
    {
        itemSearch: graphql`
            fragment SbRespRefineTopAppliedFilters_itemSearch on ItemSearchQueryConnection {
                ...useMeasurementUnit_itemSearch
                clearAppliedFiltersLinkReference
                filters {
                    name
                    localizedFilterName
                    values {
                        displayName
                        urlLabel
                        linkReference
                    }
                }
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                        displayName
                        hexCode
                    }
                }
            }
        `,
        user: graphql`
            fragment SbRespRefineTopAppliedFilters_user on User {
                ...useMeasurementUnit_user
            }
        `,
    }
);
