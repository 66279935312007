/**
 * @generated SignedSource<<57d766fc25636ca4e4cb948ef89566f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespRefineMenu_filters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_filters" | "SbSharedRefineMenuFilters_filters">;
  readonly " $fragmentType": "BuyRespRefineMenu_filters";
}>;
export type BuyRespRefineMenu_filters$key = ReadonlyArray<{
  readonly " $data"?: BuyRespRefineMenu_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespRefineMenu_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BuyRespRefineMenu_filters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_filters"
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "80da3ec22a8ad827df2096b59587317d";

export default node;
