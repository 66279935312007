import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'dibs-react-intl';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';
import { colors } from 'dibs-sassy/exports/colors';
import { spacingNumbers } from 'dibs-sassy/exports/spacing';
import {
    fontFamily,
    headerFontSize,
    headerFontSizeNumber,
    lineHeight,
} from 'dibs-sassy/exports/fonts';

import { encode64 } from '../../utils/encode';
import { messages } from '../buyMessages';
import styles from './BuySharedNoInventoryAvailable.scss';

const wrapCanvasText = ({
    ctx,
    text,
    maxWidth,
}: {
    ctx: CanvasRenderingContext2D;
    text: string;
    maxWidth: number;
}): string[] => {
    const words = text.split(' ');
    let currentLine = '';
    const linesToRender = [];

    for (let i = 0; i < words.length; i++) {
        const currentWord = `${words[i]} `;
        const combinedWidth = ctx.measureText(`${currentLine}${currentWord}`).width;

        if (combinedWidth > maxWidth) {
            linesToRender.push(currentLine);
            currentLine = currentWord;
        } else {
            currentLine += currentWord;
        }
    }

    linesToRender.push(currentLine);
    return linesToRender;
};

type Props = {
    isMobile: boolean;
};

export const BuySharedNoInventoryAvailable: FC<Props> = ({ isMobile }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [canvasContainerWidth, setCanvasContainerWidth] = useState<number | undefined>(undefined);
    const intl = useIntl();
    const translatedMessage = intl.formatMessage(messages.noInventoryAvailable);
    const triggerCanvasPaint = (): void => {
        const canvas = canvasRef.current;
        setCanvasContainerWidth(canvas?.clientWidth);
    };
    const [onWindowResize] = useDebouncedCallback(triggerCanvasPaint);

    useEffect(() => {
        triggerCanvasPaint();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => window.removeEventListener('resize', onWindowResize);
    }, [onWindowResize]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        if (canvas && ctx) {
            const fontSize = isMobile
                ? { number: headerFontSizeNumber.small, pixels: headerFontSize.small }
                : { number: headerFontSizeNumber.medium, pixels: headerFontSize.medium };
            const { normal: lineHeightNormal } = lineHeight;
            const wrappedTextList = wrapCanvasText({
                ctx,
                text: translatedMessage,
                maxWidth: canvasContainerWidth || canvas.clientWidth,
            });

            canvas.width = canvasContainerWidth || canvas.clientWidth;
            canvas.height =
                fontSize.number * lineHeightNormal * wrappedTextList.length +
                spacingNumbers.sassySpacingXxSmall;
            ctx.font = fontFamily.header(fontSize.pixels);
            ctx.fillStyle = colors.sassyColorPitchBlack;
            ctx.textAlign = isMobile ? 'left' : 'center';

            const textXPosition = isMobile ? 0 : canvas.width / 2;
            for (let i = 0; i < wrappedTextList.length; i++) {
                const textYPosition =
                    i === 0
                        ? fontSize.number * lineHeightNormal
                        : fontSize.number * lineHeightNormal * (i + 1);
                ctx.fillText(wrappedTextList[i], textXPosition, textYPosition);
            }
        }
    }, [isMobile, canvasContainerWidth, translatedMessage]);

    // data-mg attribute is used for automation testing to check the text value
    // 'mg-' prefix is added to the base64 encoded text to prevent google from crawling the text value
    // To get the text value from the attribute, remove 'mg-' prefix and decode the rest
    return (
        <canvas
            ref={canvasRef}
            data-tn={`tn-${encode64(translatedMessage)}`}
            className={styles.noInventoryAvailable}
            aria-label={translatedMessage}
            // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
            role="img"
        />
    );
};
